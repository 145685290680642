import { H4 } from 'common/styles/Headings.styled';
import useBusinessUnit from 'hooks/useBusinessUnit';
import useCountryCode from 'hooks/useCountryCode';
import useFaqLink from 'hooks/useFaqLink';
import { IInitialPrice } from 'model/interfaces';
import { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';
import { AsideFooterLink } from './Aside.styled';
import {
  EnrollmentBooking,
  EnrollmentContainer,
  EnrollmentPriceContainer,
  H2,
  PriceAndCurrency,
  QuestionContainer
} from './AsideProductInfo.styled';
interface Props {
  initialPrice: IInitialPrice;
}
import { useTranslation } from 'next-i18next';

const EnrollmentPrice: FunctionComponent<Props> = ({ initialPrice }) => {
  const [country] = useCountryCode();
  const businessUnit = useBusinessUnit(state => state.businessUnit);

  const [faqLink] = useFaqLink(country, businessUnit);
  const { t } = useTranslation('main');

  return (
    <EnrollmentContainer>
      <div></div>
      <EnrollmentBooking>
        <H2>{t('payment.totalPay')}</H2>
        <EnrollmentPriceContainer>
          <span> {t('payment.booking')}:</span>
          <PriceAndCurrency>
            <span>{initialPrice.currency} </span>
            <NumberFormat
              value={initialPrice.enrollmentAmount}
              thousandSeparator={'.'}
              decimalSeparator={','}
              displayType={'text'}
            />
          </PriceAndCurrency>
        </EnrollmentPriceContainer>
      </EnrollmentBooking>
      <QuestionContainer>
        <H4 marginTop={24}>{t('group.doubts')}</H4>

        <AsideFooterLink>
          <a href={faqLink} target="_blank">
            <span id="questionsFooterLink">{t('group.frequentQuestions')}</span>
          </a>
        </AsideFooterLink>
      </QuestionContainer>
    </EnrollmentContainer>
  );
};

export default EnrollmentPrice;
