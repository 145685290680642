import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  BlockBody,
  BlockHead,
  AsideProductGroupItem,
  BlockRowLeft,
  BlockRowRight,
  BlockRow,
  DetailBlock,
  Remarked,
  Title,
  Price,
  Hr,
  TextPrice,
  PriceParagraph
} from './AsideSelectionDetail.styled';
import {
  IApplicant,
  IEnrollmentPrice,
  IOffer,
  IPaymentPlan,
  IProduct,
  IProductGroup
} from 'model/interfaces';
import { ModifyButton } from 'common/styles/Buttons.styled';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import NumberFormat from 'react-number-format';
import { Currency } from 'hooks/useCurrency';
import { useRouter } from 'next/router';
import { getEnrollmentPrice } from 'utils/offer';
import { useTranslation } from 'next-i18next';
import useOnlyConfiguration from 'hooks/useOnlyConfiguration';
import PaymentPlanTitle from 'components/PaymentPlan/PaymentPlanTitle';
import useIsInstantPayment from 'hooks/useIsInstantPayment';
import useProduct from 'hooks/useProduct';
import { EBusinessUnit } from 'model/enums';
import useQueryParamsStore from 'hooks/useQueryParamsStore';

interface Props {
  offer: IOffer | null | undefined;
  group?: IProductGroup | null;
  applicant?: IApplicant | null;
  plan?: IPaymentPlan | null;
  setAsideToggled: (value: boolean) => void;
  currency: Currency | undefined;
  editableGroup?: boolean;
  product: IProduct;
}

interface TotalProps {
  enrollment: IEnrollmentPrice | undefined;
  plan?: IPaymentPlan;
  onePayment?: boolean;
}

const TotalEnrollmentPayment: FunctionComponent<TotalProps> = ({ enrollment }) => {
  return <Remarked>
    {enrollment?.currency}{' '}
    <NumberFormat
      value={enrollment?.remarkedAmount}
      thousandSeparator={'.'}
      decimalSeparator={','}
      displayType={'text'}
    />
</Remarked>
};

const TotalPayment: FunctionComponent<TotalProps> = ({ enrollment, plan, onePayment }) => {
  const showRemarked = enrollment && enrollment?.remarkedAmount > enrollment?.amount;
  return <>
  {
    enrollment && plan && (
      onePayment ? <Title>
          {enrollment.currency}{' '}
          <NumberFormat
            value={enrollment.amount + plan.programAmount}
            thousandSeparator={'.'}
            decimalSeparator={','}
            displayType={'text'}
          />
        </Title>
        : showRemarked && <TotalEnrollmentPayment enrollment={enrollment} onePayment={onePayment} />
    )
  }
  </>
};

const EnrollmentRemarkerAmount = ({enrollment}) => {
  return <>
    {enrollment.remarkedAmount > enrollment.amount && (
      <Remarked>
        {enrollment.currency}{' '}
        <NumberFormat
          value={enrollment.remarkedAmount}
          thousandSeparator={'.'}
          decimalSeparator={','}
          displayType={'text'}
        />
      </Remarked>
    )}
  </>
};

const EnrollmentAmount = ({enrollment}) => {
  return <>
    {enrollment.currency}{' '}
    <NumberFormat
      value={enrollment.amount}
      thousandSeparator={'.'}
      decimalSeparator={','}
      displayType={'text'}
    />
  </>
};

const EnrollmentPayment = ({ enrollment, onlyConfiguration, onePayment }) => {
  const { t } = useTranslation('main');
  const { isInstantPayment } = useIsInstantPayment();

  return (<>
  <BlockRow>
    <BlockRowLeft>{t('payment.booking')}</BlockRowLeft>
    <BlockRowRight>
      {onlyConfiguration ? (
        <Title>
          <Price>{t('payment.bonus')}</Price>
        </Title>
      ) : (
        (!isInstantPayment || !onePayment)
          ? <EnrollmentAmount enrollment={enrollment} />
          : <EnrollmentRemarkerAmount enrollment={enrollment} />
      )}
    </BlockRowRight>
  </BlockRow>
  <BlockRow>
    <BlockRowLeft></BlockRowLeft>
    <BlockRowRight>
      {(isInstantPayment && onePayment && !onlyConfiguration) && <EnrollmentAmount enrollment={enrollment} />}
    </BlockRowRight>
  </BlockRow>
  </>)
};

const AsideSelectionDetail: FunctionComponent<Props> = ({
  offer,
  group,
  applicant,
  plan,
  setAsideToggled,
  currency,
  editableGroup,
  product
}) => {
  const router = useRouter();
  const { params } = useQueryParamsStore();
  const { t } = useTranslation('main');
  const onlyConfiguration = useOnlyConfiguration(
    state => state.onlyConfiguration
  );
  const onePayment = plan?.installmentCount === 1;
  const { isInstantPayment } = useIsInstantPayment();
  const { isBusinessUnit } = useProduct(product);
  const isOnDemand = isBusinessUnit(EBusinessUnit['on_demand']);

  const modifyGroup = () => {
    setAsideToggled(false);
    router.push(
      {
        pathname: '/products/[id]/group',
        query: { ...params, group: null }
      },
      undefined,
      { shallow: true }
    );
  };

  const modifyApplicant = () => {
    setAsideToggled(false);
    router.push(
      {
        pathname: '/products/[id]/group',
        query: params
      },
      undefined,
      { shallow: true }
    );
  };

  const enrollment = getEnrollmentPrice(offer, currency?.code);

  const [showGroupApplicant, setShowGroupApplicant] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const amount = (enrollment?.amount ?? 0) + (plan?.programAmount ?? 0);

  useEffect(() => {
    setShowGroupApplicant(!!group && !!applicant);
  }, [group, applicant]);

  useEffect(() => {
    setShowPlan(!!plan);
  }, [plan]);

  return (
    <section>
      {showGroupApplicant && (
        <>
          {group && !isOnDemand && (
            <DetailBlock>
              <BlockHead>
                <Title>{t('payment.beginning')}</Title>
                {editableGroup && (
                  <ModifyButton
                    color="var(--dh-white3)"
                    hover="var(--dh-white4)"
                    onClick={modifyGroup}
                  >
                    {t('payment.modify')}
                  </ModifyButton>
                )}
              </BlockHead>

              <BlockBody>
                <AsideProductGroupItem group={group} maxLines={2} />
              </BlockBody>
            </DetailBlock>
          )}

          <DetailBlock>
            <BlockHead>
              <Title> {t('payment.contactDetails')}</Title>
              <ModifyButton
                color="var(--dh-white3)"
                hover="var(--dh-white4)"
                onClick={modifyApplicant}
              >
                {t('payment.modify')}
              </ModifyButton>
            </BlockHead>

            <BlockBody>
              <BlockRow>
                {applicant!.firstName + ' ' + applicant!.lastName}
              </BlockRow>
              <BlockRow>{applicant!.email}</BlockRow>
              <BlockRow>{formatPhoneNumberIntl(applicant!.phone)}</BlockRow>
            </BlockBody>
          </DetailBlock>
        </>
      )}
      {showPlan && plan && (
        <DetailBlock>
          <BlockHead>
            <Title> {t('payment.paymentPlan')}</Title>
          </BlockHead>
          <BlockBody>
            <BlockRow>
              <BlockRowLeft>
                <PaymentPlanTitle plan={plan} product={product} />:
              </BlockRowLeft>
              <BlockRowRight>
                <TextPrice>
                  {plan.remarkedInstallmentAmount > plan.installmentAmount && (
                    <Remarked>
                      {plan.currency}{' '}
                      <NumberFormat
                        value={plan.remarkedInstallmentAmount}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        displayType={'text'}
                      />
                    </Remarked>
                  )}
                  <Title>
                    <Price>
                      {plan.currency}{' '}
                      <NumberFormat
                        value={plan.installmentAmount}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        displayType={'text'}
                      />
                    </Price>
                  </Title>
                  {(!onePayment || !isInstantPayment) && !onlyConfiguration && !isOnDemand && (
                    <PriceParagraph>{t('common.firstPayment')}</PriceParagraph>
                  )}
                </TextPrice>
              </BlockRowRight>
            </BlockRow>
            {onePayment && isInstantPayment && !onlyConfiguration && enrollment && !isOnDemand && (
              <BlockBody>
                <EnrollmentPayment
                  enrollment={enrollment}
                  onlyConfiguration={onlyConfiguration}
                  onePayment={onePayment}
                />
              </BlockBody>
            )}
            <Hr />

            {enrollment && !isOnDemand && (
              <BlockRow highlight>
                <BlockRowLeft>
                  <Title highlight>{t('payment.totalPay')}</Title>
                </BlockRowLeft>
                <BlockRowRight>
                  {!onlyConfiguration && !isInstantPayment && (
                    <>
                      {enrollment.remarkedAmount > enrollment.amount && (
                        <Remarked>
                          {enrollment.currency}{' '}
                          <NumberFormat
                            value={enrollment.remarkedAmount}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            displayType={'text'}
                          />
                        </Remarked>
                      )}
                    </>
                  )}
                  {isInstantPayment && !onlyConfiguration && (
                    <TotalPayment enrollment={enrollment} plan={plan} onePayment={onePayment} />
                  )}
                </BlockRowRight>
              </BlockRow>
            )}
            {(!isInstantPayment || !onePayment || onlyConfiguration) && enrollment && !isOnDemand && (
              <EnrollmentPayment
                enrollment={enrollment}
                onlyConfiguration={onlyConfiguration}
                onePayment={onePayment}
              />
            )}
          </BlockBody>
        </DetailBlock>
      )}
    </section>
  );
};

export default AsideSelectionDetail;
