import create from 'zustand';

type UseOnlyConfigurationType = {
  onlyConfiguration: boolean;
  setOnlyConfiguration: (onlyConfiguration: boolean) => void;
};

/** @todo: Incorporar useOnlyConfiguration dentro de useProduct */
const useOnlyConfiguration = create<UseOnlyConfigurationType>(
  set => ({
    onlyConfiguration: false,
    setOnlyConfiguration: newOnlyConfiguration => set(() => ({ onlyConfiguration: newOnlyConfiguration }))
  })
);

export default useOnlyConfiguration;