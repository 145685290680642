import styled, { css } from 'styled-components';

interface StyleProps {
  color?: string;
  hover?: string;
}

export const ModifyButton = styled.button`
  font-weight: 700;
  ${({ color = 'var(--dh-gray5)' }: StyleProps) =>
    color &&
    css`
      color: ${color};
    `}
  cursor: pointer;
  align-self: start;
  border: 0;
  padding: 0;
  background-color: transparent;
  transition-property: color;
  transition: var(--hoverTransition);
  text-decoration: underline;
  &:hover {
    ${({ hover = 'var(--dh-gray11)' }: StyleProps) =>
      hover &&
      css`
        color: ${hover};
      `}
  }
  &:focus {
    outline: none;
  }

  &:focus-visible {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }

  /* Mobile */
  @media screen and (max-width: 768px) {
    margin-left: 24px;
  }
`;
