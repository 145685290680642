import useCountryCode from 'hooks/useCountryCode';
import { useCallback, useEffect } from 'react';
import { IProduct, IProductGroup } from 'model/interfaces';
import shallow from 'zustand/shallow';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { useRouter } from 'next/router';
import apolloClient, { FetchPolicy } from 'graphQL/client';
import { GET_EVENT } from 'graphQL/queries';
import useQueryParamsStore from './useQueryParamsStore';

type UseGroupStoreType = {
  isReady: boolean;
  group: IProductGroup | undefined | null;
  fixed: boolean;
  setIsReady: (isReady: boolean) => void;
  setGroup: (group: IProductGroup) => void;
  setFixed: (fixed: boolean) => void;
  clear: () => void;
};

const useGroup = (product: IProduct) => {
  const router = useRouter();
  const [country] = useCountryCode();
  const { params, setParams, clearParams } = useQueryParamsStore();

  const [
    group,
    _setGroup,
    clearGroupStore,
    isReady,
    setIsReady,
    fixed,
    setFixed
  ] = useGroupStore(
    state => [
      state.group,
      state.setGroup,
      state.clear,
      state.isReady,
      state.setIsReady,
      state.fixed,
      state.setFixed
    ],
    shallow
  );

  const setGroup = (value: IProductGroup) => {
    setParams({ group: value.code }, true);
    _setGroup(value);
  };

  const unsetGroup = () => {
    clearParams('group');
    clearGroupStore();
  };

  const getGroup = async (name: string) => {
    if (!name) return null;
    try {
      const {
        data: { getEvent }
      } = await apolloClient.query({
        query: GET_EVENT,
        variables: {
          commissionName: name,
          productID: +product.id,
          country: country
        },
        fetchPolicy: FetchPolicy['cache-first']
      });
      return getEvent;
    } catch (e) {
      return null;
    }
  };

  const groupIsAvailable = (group: IProductGroup) => {
    return !group.soldout;
  };

  const getInitialGroup = useCallback(async () => {
    // Default to URL param if set
    if (params.group || params.group === '') {
      const userGroup = await getGroup(params.group);
      if (userGroup && groupIsAvailable(userGroup)) {
        _setGroup(userGroup);

        if (params.fg || params.fg === '') {
          setFixed(true);
        }
      } else {
        unsetGroup();
      }
    }
    setIsReady(true);
  }, [params.group, group, product]);

  useEffect(() => {
    if (router.isReady) getInitialGroup();
  }, [router.isReady, params.group, group, product]);

  return {
    isReady,
    group,
    setGroup,
    unsetGroup,
    fixed
  };
};

const useGroupStore = create<UseGroupStoreType>(
  persist(
    set => ({
      isReady: false,
      group: undefined,
      fixed: false,
      setGroup: group => set({ group, isReady: true }),
      setIsReady: (isReady: boolean) => set({ isReady }),
      setFixed: (fixed: boolean) => set({ fixed }),
      clear: () => {
        set({ group: null, isReady: true, fixed: false });
      }
    }),
    {
      name: 'group', // unique name
      getStorage: () => sessionStorage
    }
  )
);

export default useGroup;
