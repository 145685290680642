import React, { FunctionComponent } from 'react';
import { ProductInfoText } from './AsideProductInfo.styled';
import { IOffer, IProduct } from 'model/interfaces';
import { getInitialPrice } from 'utils/offer';
import { Currency } from 'hooks/useCurrency';
import EnrollmentPrice from './EnrollmentPrice';
import useCluster from 'hooks/useCluster';
import useOnlyConfiguration from 'hooks/useOnlyConfiguration';
import useProduct from 'hooks/useProduct';
import { EBusinessUnit } from 'model/enums';

interface Props {
  product: IProduct;
  offer: IOffer | null | undefined;
  currency: Currency | undefined;
  showDetail?: boolean;
}

const AsideProductInfo: FunctionComponent<Props> = ({
  product,
  offer,
  currency,
  showDetail = true
}) => {
  const initialPrice = getInitialPrice(offer, currency?.code);
  const { description } = useCluster(product);
  const onlyConfiguration = useOnlyConfiguration(state => state.onlyConfiguration);
  const { isBusinessUnit } = useProduct(product);
  const isOnDemand = isBusinessUnit(EBusinessUnit['on_demand']);

  return (
    <>
      <ProductInfoText>{description}</ProductInfoText>
      {initialPrice && initialPrice.amount > 0 && (
        !onlyConfiguration && showDetail && !isOnDemand && <EnrollmentPrice initialPrice={initialPrice}></EnrollmentPrice>
      )}
      {/* <AsideProductFeatures product={product} /> */}
    </>
  );
};

export default AsideProductInfo;
