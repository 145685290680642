import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  IApplicant,
  IOffer,
  IPaymentPlan,
  IProduct,
  IProductGroup
} from 'model/interfaces';
import useFaqLink from 'hooks/useFaqLink';
import { Currency } from 'hooks/useCurrency';
import useDisclaimer from 'hooks/useDisclaimer';

import AsideProductInfo from 'components/Aside/AsideProductInfo';
import AsideSelectionDetail from 'components/Aside/AsideSelectionDetail';
import TermsDisclaimer from 'components/TermsDisclaimer/TermsDisclaimer';

import {
  Container,
  Wrapper,
  AsideHeader,
  ToggleButton,
  AsideBody,
  AsideFooter,
  SubmitButton,
  GlobalStyle,
  HeaderLabel,
  HeaderName,
  AsideFooterLink,
  AlertBadge,
  MenuIcon,
  XIcon
} from './Aside.styled';
import { H4 } from 'common/styles/Headings.styled';
import { useTranslation } from 'next-i18next';
import useCluster from 'hooks/useCluster';
import useIsInstantPayment from 'hooks/useIsInstantPayment';
import useProduct from 'hooks/useProduct';
import { EBusinessUnit } from 'model/enums';

export const SCHEDULE_ENDPOINT = process.env.NEXT_PUBLIC_SCHEDULE_ENDPOINT;

interface Props {
  product: IProduct;
  group?: IProductGroup | null;
  applicant?: IApplicant | null;
  plan?: IPaymentPlan | null;
  offer?: IOffer | null;
  disableButton?: boolean;
  isLoading?: boolean;
  review?: boolean;
  currency: Currency | undefined;
  country?: string | undefined;
  editableGroup?: boolean;
  showInfo?: boolean;
  showDetail?: boolean;
  onContinue?: () => void;
  onConfirm?: () => void;
}

const Aside: FunctionComponent<Props> = ({
  product,
  group,
  applicant,
  plan,
  offer,
  disableButton,
  isLoading,
  onContinue,
  onConfirm,
  review,
  currency,
  country,
  editableGroup,
  showInfo,
  showDetail = true
}) => {
  const { t } = useTranslation('main');
  const [asideToggled, setAsideToggled] = useState(false);
  const [alertNew, setAlertNew] = useState(false);
  const [hideProductInfo, setHideProductInfo] = useState(false);
  const [showFooterLinks, setShowFooterLinks] = useState(true);
  const [faqLink] = useFaqLink(country, product.businessUnit);
  const disclaimer = useDisclaimer(state => state.disclaimer);
  const { name: clusterName } = useCluster(product);
  const { isInstantPayment } = useIsInstantPayment();
  const onePayment = plan?.installmentCount === 1;
  const { isBusinessUnit } = useProduct(product);
  const isOnDemand = isBusinessUnit(EBusinessUnit['on_demand']);
  const showTyC = product?.shouldAcceptTyC && review;

  const toggleAside = () => {
    setAsideToggled(!asideToggled);
    setAlertNew(false);
  };

  useEffect(() => {
    setHideProductInfo(!showInfo || !!applicant);
  }, [applicant, plan]);

  useEffect(() => {
    if ((group && applicant) || plan) setAlertNew(true);
  }, [group, applicant, plan]);

  useEffect(() => {
    setShowFooterLinks(!plan && !review);
  }, [plan, review]);

  return (
    <Container asideToggled={asideToggled}>
      <GlobalStyle asideToggled={asideToggled} />
      <Wrapper>
        <AsideHeader>
          <div>
            <HeaderLabel>{product.businessUnitAlias}</HeaderLabel>
            <HeaderName asideToggled={asideToggled}>
              {clusterName}
            </HeaderName>
          </div>
          <ToggleButton
            onClick={toggleAside}
            asideToggled={asideToggled}
            alertNew={alertNew}
          >
            <AlertBadge></AlertBadge>
            <MenuIcon />
            <XIcon />
          </ToggleButton>
        </AsideHeader>

        <AsideBody asideToggled={asideToggled}>
          <section>
            {!hideProductInfo && (
              <AsideProductInfo
                product={product}
                offer={offer}
                currency={currency}
                showDetail={!review}
              />
            )}
            {showDetail && <AsideSelectionDetail
              group={group}
              applicant={applicant}
              plan={plan}
              offer={offer}
              setAsideToggled={setAsideToggled}
              currency={currency}
              editableGroup={editableGroup}
              product={product}
            />}
          </section>

          <AsideFooter>
            {showTyC && <TermsDisclaimer country={country} />}

            {showFooterLinks &&(
              <>
                {!isOnDemand && <H4 marginTop={24}>{t('group.reservationDoubts')}</H4>}
                <AsideFooterLink>
                  <a href={faqLink} target="_blank">
                    <span>{t('group.frequentQuestions')}</span>
                  </a>
                </AsideFooterLink>
              </>
            )}

            <SubmitButton
              variant="contained"
              size="large"
              disableElevation
              fullWidth
              pending={isLoading}
              disabled={showTyC ? (!disclaimer && !!onConfirm) : disableButton}
              onClick={() =>
                onConfirm ? onConfirm() : onContinue ? onContinue() : () => {}
              }
            >
              {product.onlyConfiguration && review
                ? t('review.finalize')
                : !product.onlyConfiguration && review && !isOnDemand
                ? isInstantPayment && onePayment ? t('review.payNow') : t('review.payReservation')
                : isOnDemand && review ? t('review.onlyConfirm')
                : t('group.continue')}
            </SubmitButton>
          </AsideFooter>
        </AsideBody>
      </Wrapper>
    </Container>
  );
};

export default Aside;
